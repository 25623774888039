.listy {
    list-style-type: circle;
    font-size: 1.5rem;
    list-style-position: outside;
    background-color: green;
    line-height: 2rem;
    margin-top:-3vw;
}

.project {
    width: 30vw;
    @include respond(phone) {
        width: 90vw;
    }
    @include respond(tab-land) {
    width: 40vw;
    }
}