.experiences {
    padding-bottom: 2rem;
    background-color: $color-background-secondary;
    padding-top: $nav-height;


    &__header {
        color:$color-secondary;
        font-size:$font-section;
        text-align:center;
        margin-bottom: -2vw;
        font-family: 'Langar', cursive;
    }

    &__horizontal {
        width: 5vw;
        margin:auto;
        height:12px;
        border-radius: 7px;
        background-color:$color-primary;
    }

    &__content {
        margin-top: 2vw;
    }
    

}