.icon {
    display: block;
    margin: 0 auto;
    width: 15vw;

    &__image {
        height: 7vw;
        width: 7vw;

        &:hover {
          transform: scale(1.05);
          transition: all 1s;
      }
    }

    &__title {
        font-size: $font-title;
        color:$color-secondary;
        line-height: normal;
    }

    &__subtitle {
        font-size: $font-subtitle;
        overflow: visible;
        word-wrap: break-word;
        white-space: initial;
        color: $color-secondary;
        line-height: normal;
    }
}
