.main__footer {
  height: fit-content;
  padding: 50px 0;

  &__box {
    cursor: pointer;
    font-size: 18pt;
    height: 50px;
    width: 50px;
    overflow: hidden;
    align-items: center;
    display: flex;
    margin:-4vw auto auto auto;
    justify-content: center;
    background-color: $color-background-fourth;
    border-radius:$border-radius;
    color:$color-white;

    &:hover {
      background-color: $color-background-fourth-slight;
      transition: all 0.5s;
      border-radius: 10%;
    }
    
    &__arrow {
      transform: rotate(-90deg);
    }
  }

  &__icons {
    margin: 4rem auto 0 auto;
    display:flex;
    justify-content: center;
  
    &__icon {
      cursor: pointer;
      font-size: 18pt;
      height: 50px;
      width: 50px;
      margin: 0 15px;
      overflow: hidden;
      background-color: $color-primary;
      border-radius:$border-radius;
      transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 2s;
    }

    &__logo {
      height: 50px;
      width: 50px;
      color: #fff;
      cursor: pointer;
      padding: 10px;
      transition: background-color 0.3s, color 0.3s, box-shadow 0.3s, transform 0.3s;
    }

    #icon:hover{
      background-color: $color-background-fourth;
    }

    #icon:hover #logo {
      background-color: $color-background-fourth;
      -webkit-animation: toBottomFromTop 0.3s forwards;
      -moz-animation: toBottomFromTop 0.3s forwards;
      animation: toBottomFromTop 0.3s forwards;
      animation-iteration-count: 1;
      color: #fff;
    }

    &__link {
      text-decoration: none;

      &:hover {
        color: $color-secondary;
        transition: all 0.5s;
        text-decoration: none;
      }
    }
  }
}
