.aboutme {
    height:fit-content;
    padding: $nav-height 0 2rem 0;
    background-color: $color-white;
    margin-top: 100vh;

    &__header {
        color:$color-secondary;
        font-size:$font-section;
        text-align:center;
        margin-bottom: -2vw;
        font-family: 'Langar', cursive;
    }

    &__horizontal {
        width: 5vw;
        margin:auto;
        height:12px;
        border-radius: 7px;
        background-color:$color-primary;
    }

    &__content {
        padding-bottom: 2vw;

        &__icons {
            margin: 2vw auto auto auto;
            display: flex;
            width: 80%;
            text-align: center;

            @include respond(phone) {
                display: grid;
                grid-template-columns: auto auto;
                grid-gap: 2vw;
            }
        }

        &__description {
            display: grid;
            grid-template-columns: 35% 65%;
            margin-top: 2vw;

            &__image {
                margin:auto;
                width: 20vw;
                height: 20vw;
                border-radius: 50%;
                object-fit: cover;

                @include respond(phone) {
                    width: 30vw;
                    height: 30vw;
                }
            } 

            &__text {
                padding-right: 15vw;

                &__title {
                    font-size: 4rem;
                }
                &__text {
                    font-size: 2rem;
                    text-align: justify;
                    text-justify: inter-word;

                    @include respond(phone) {
                        width: 90vw;
                    }
                }

                &__link {
                    text-decoration: none;
                }

                &__button {
                    cursor: pointer;
                    width: 8vw;
                    height: 3vw;
                    font-weight:bold;
                    font-size:$font-text;

                    border-color: $color-primary;
                    border-style: solid;
                    border-width: 3px;
                    
                    color: $color-primary;
                    background-color: transparent;
                    border-radius: 5%;
                    
                    &:hover {
                        transform: scale(1.05);
                        transition: all 0.5s;
                    }
                }
            }

            @include respond(tab-port) {
                display: grid;
                grid-template-columns: auto;

                &__text {
                    margin: 2vw;

                    &__title {
                        font-size: 4rem;
                    }

                    &__text {
                        font-size: 1.75rem;
                    }

                    &__button {
                        width: 20vw;
                        height: 6vw;
                        margin-top: 1vw;
                    }
                }
              }
        }

    }
}

