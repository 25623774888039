.main {
  &__header {
    background: url("../../assets/teal.jpeg") no-repeat center center fixed;     -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100%;
    width: 100%;

    @include respond(phone) {
      width: 110vw;
    }

    @include respond(medium-phone) {
      width: 112vw;
    }

    @include respond(small-phone) {
      width: 130vw;
    }
    @include respond(tiny-phone) {
      width: 160vw;
    }

    &__name {
      color: $color-white;
      text-shadow: -3px -3px 0 $color-primary, 4px -4px 0 $color-primary,
        -3px 3px 0 $color-primary, 3px 3px 0 $color-primary;
      font-size: $font-header;
      text-align: center;
      padding-top: 5vw;
      margin: auto auto -3vw auto;
      font-family: "Langar", cursive;
    }

    &__horizontal {
      width: 10vw;
      margin: auto;
      height: 10px;
      border-radius: 10px;
      background-color: $color-white;
      border-color: $color-primary;
      border-style: solid;
      border-width: 2px;
    }

    &__aboutOuter {
      margin-left: 27vw;
      margin-top: 3vw;
      line-height: normal;
    }

    &__about {
      color: $color-white;
      font-size: $font-section;
      text-align: center;
      font-family: "Langar", cursive;
      display: inline-block;
    }

    &__logos {
      display: flex;
      justify-content: center;

      &__link {
        margin: auto 1vw;
        z-index: 999999999999 !important;
      }

      &__logo:hover {
        transform: scale(1.2);
        transition: all 0.5s;
      }
    }

    &__arrow {
      position: relative;
      bottom: -2rem;
      left: 50%;
      margin-left: -20px;
      width: 40px;
      height: 40px;
      margin-top: 5vw;
      z-index: 999999;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNi4wLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNTEycHgiIGhlaWdodD0iNTEycHgiIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA1MTIgNTEyIiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIGZpbGw9IiNGRkZGRkYiIGQ9Ik0yOTMuNzUxLDQ1NS44NjhjLTIwLjE4MSwyMC4xNzktNTMuMTY1LDE5LjkxMy03My42NzMtMC41OTVsMCwwYy0yMC41MDgtMjAuNTA4LTIwLjc3My01My40OTMtMC41OTQtNzMuNjcyICBsMTg5Ljk5OS0xOTBjMjAuMTc4LTIwLjE3OCw1My4xNjQtMTkuOTEzLDczLjY3MiwwLjU5NWwwLDBjMjAuNTA4LDIwLjUwOSwyMC43NzIsNTMuNDkyLDAuNTk1LDczLjY3MUwyOTMuNzUxLDQ1NS44Njh6Ii8+DQo8cGF0aCBmaWxsPSIjRkZGRkZGIiBkPSJNMjIwLjI0OSw0NTUuODY4YzIwLjE4LDIwLjE3OSw1My4xNjQsMTkuOTEzLDczLjY3Mi0wLjU5NWwwLDBjMjAuNTA5LTIwLjUwOCwyMC43NzQtNTMuNDkzLDAuNTk2LTczLjY3MiAgbC0xOTAtMTkwYy0yMC4xNzgtMjAuMTc4LTUzLjE2NC0xOS45MTMtNzMuNjcxLDAuNTk1bDAsMGMtMjAuNTA4LDIwLjUwOS0yMC43NzIsNTMuNDkyLTAuNTk1LDczLjY3MUwyMjAuMjQ5LDQ1NS44Njh6Ii8+DQo8L3N2Zz4=);
      background-size: contain;
    }

    @include respond(tab-land) {
      &__name {
        font-size: 15rem;
      }
      &__about {
        font-size: 8rem;
      }
      &__logos {
        &__logo {
          width: 5vw;
          height: 5vw;
        }
      }
    }
  }
}

.animation {
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
