.courses {
    padding-bottom: 2rem;
    background-color: $color-white;
    padding-top: $nav-height;


    &__header {
        color:$color-secondary;
        font-size:$font-section;
        text-align:center;
        margin-bottom: -2vw;
        font-family: 'Langar', cursive;
    }

    &__horizontal {
        width: 5vw;
        margin:auto;
        height:12px;
        border-radius: 7px;
        background-color:$color-primary;
    }

    &__subheader {
        color:$color-secondary;
        font-size:$font-subsection;
        text-align:center;
        font-family: 'Langar', cursive;
    }

    &__content {
        margin-top: 2vw;
        display:grid;
        grid-template-columns: auto auto;
        align-items: start;
        align-self: start;

        @include respond(tab-port) {
            grid-template-columns: auto;
        }
    }
    

}