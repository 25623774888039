.notfound {
    display: flex;
    justify-content: center;
    align-items: center;
    
  
    &__content {
      &__label {
        text-align: center;
        color: $color-white;
        font-size: $font-section;
        margin-bottom: 2rem;
      }



      &__link {
        text-decoration: none;
      }

      &__button {
        height: 4vw;
        width: 10vw;
        display: block;
        margin: auto;
        background-color: $color-primary;
        color: $color-white;
        outline: none;
        font-weight: bold;
        font-size: $font-subtitle;

        &:hover {
          cursor: pointer;
          transform: scale(1.1);
          transition: all 1s;
        }
      }
    }
  }
  
