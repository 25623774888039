// COLORS
$color-primary: #63A9AE;

// Section Title Color
$color-secondary: black;

$color-secondary-text: #6e6e6e;

$color-third: #46474A;

// Footer
$color-background: #323232;

// Second White for Backgrounds
$color-background-secondary: #F5F5F5;

//Background of Experiences + Courses
$color-background-third: #b3b3b3;

// Arrow Up in
$color-background-fourth: #1D242E;
$color-background-fourth-slight:#242d3a;

$color-white: #fcfcfc;
$color-black: #000;

// FONT
$default-font-size: 1.6rem;
$font-header: 15rem;
$font-section: 8rem;
$font-subsection: 4rem;
$font-title: 3rem;
$font-subtitle: 2rem;
$font-text: 1.5rem;

// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;

// Miscellaneous:
$border-radius: 0.25vw;
$nav-height: 55px;
