.interests {
    padding-bottom: 2rem;
    background-color: $color-background-secondary;
    padding-top: $nav-height;


    &__header {
        color:$color-secondary;
        font-size:$font-section;
        text-align:center;
        margin-bottom: -2vw;
        font-family: 'Langar', cursive;
    }

    &__horizontal {
        width: 5vw;
        margin:auto;
        height:12px;
        border-radius: 7px;
        background-color:$color-primary;
    }

    &__content {
        margin:auto;
        padding-bottom: 2vw;

        &__icons {
            margin: 2vw auto auto auto;
            display: flex;
            width: 80%;
            text-align: center;
            
            @include respond(phone) {
                display:grid;
                grid-template-columns: auto;
                grid-gap: 5vw;
            }
        }
    }
}

