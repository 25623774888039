.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes toBottomFromTop {
  49% {
    transform: translateY(100%);
  }
  50% {
    opacity: 0;
    transform: translateY(-100%);
  }
  51% {
    opacity: 1;
  }
}