.experience {
    width: 60vw;
    height:fit-content;
    display:grid;
    margin:auto auto 3rem auto;
    grid-template-columns: 12% 100%;
    
    border-radius: $border-radius;
    background-color: $color-white;
    padding: 1rem;

    -webkit-box-shadow: 0px 0px 5px 0px #000000; 
    box-shadow: 0px 0px 5px 0px #000000;

    &:hover {
        transform: scale(1.01);
        transition: all 0.25s;
    }

    &__image {
        height:4vw;
        width:4vw;
        border-radius:0.5vw;
        vertical-align: top;
        margin: 0.5vw auto auto auto;
        display:flex;        
    }

    &__content {
        display:block;
        margin-left:1rem;
        text-align:left;

        &__header {
            color:$color-secondary;
            font-size: $font-subtitle;
        }

        &__date {
            font-size: $font-text;
            color:$color-secondary;
            margin: -1rem 0rem -2rem 0rem;
            
            #break {
                display: none;
                
                @include respond(medium-phone) {
                    display: block;
                    }
            
            }
            
        }

        &__horizontal {
            width: 35%;
            height: 2.5px;
            border: 0;
            background-color: $color-primary;
            border-radius: 5px;
        }


        &__description {
            font-size: $font-text;
            color:$color-secondary-text;
            line-height: 2rem;
            overflow-wrap: break-word;
            word-wrap: break-word;
            width: 90%;
            padding-right: 2rem;
 
            &__item {
                margin-left: 3.5rem;
            }
        }

        ul li::before {
            content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: $color-primary; /* Change the color */
            font-weight: bold; /* If you want it to be bold */
            display: inline-block; /* Needed to add space between the bullet and the text */
            width: 1em; /* Also needed for space (tweak if needed) */
            margin-left: -1em; /* Also needed for space (tweak if needed) */
        }
    }

    @include respond(tab-port) {
        width: 70vw;
      }
}