.section {
    width: 45vw;
    height:fit-content; 
    margin: 0 auto 2rem auto;
    border-radius: $border-radius;
    background-color: $color-white;
    padding: 0.5rem;
    align-self: start;
    -webkit-box-shadow: 0px 0px 5px 0px #000000; 
    box-shadow: 0px 0px 5px 0px #000000;

    @include respond(tab-port) {
        width: 90vw;
    }

    &:hover {
        transform: scale(1.01);
        transition: all 0.25s;
    }

    &__content {
        display:block;
        margin-left:1rem;

        &__header {
            color:$color-secondary;
            font-size: $font-title;
            font-weight: bold;
            margin-bottom: -0.5vw; 
        }

        &__courses {
            margin-left: 2rem;

            &__item {
                font-size: $font-text;
                color:$color-secondary;
            }
        }

    }

}