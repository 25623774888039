.nav {
  width: 100%;
  height: $nav-height;
  top: 0;
  background-color: $color-primary;
  position: fixed;
  display: inline;
  z-index: 9999;
  transition: top 0.3s;
  margin-bottom: 10vh;
  text-align: left;
  padding-top: 4px;
  margin-top: 0;
  z-index: 999;
  -webkit-overflow-scrolling:touch;
 
  &__logo {
    width: 10%;
    text-decoration: none;
    font-size: 30px;
    font-family: "Langar", cursive;
    margin: 0 0 0 10rem;
    color: $color-white;

    @include respond(tab-land) {
      margin: 0 0 0 5rem;
      width: 50%;
    }

    @include respond(tab-port) {
      width: 70%;
    }
    @include respond(phone) {
      width: 50%;
    }
  }

  &__horizontal {
    width: 100vw;
    display: flex;
    position: fixed;
    height: 5px;
    border-radius: 10px;
    background-color: $color-primary;
    border-color: $color-primary;
    vertical-align: top;
  }
}

.navigation {
  position: fixed;
  top: 0;
  margin: 0 4rem 0 0;
  display: inline-flex;
  justify-content: center;
  align-items: baseline;
  text-align: right;
  left: 35%;
  font-size: 1.5rem;


  @include respond(big-desktop) {
  }
  @include respond(semi-desktop) {
    left: 40%;
  }
  @include respond(tab-land) {
  }
  @include respond(tab-inter) {
    left: 30%;
  }

  @include respond(tab-port) {
  }
  @include respond(phone) {
   
  }
  @include respond(small-phone) {
  }

  &__nav {
    list-style-type: none;
    margin: 0;
    padding: 0;
    vertical-align: top;
    margin-top: 1rem;
    text-align: right;
  }

  &__item {
    display: inline;
  }

  &__item#fun_facts {
    @include respond(tab-land) {
      display: none;
    }
  }

  &__open {
    display: none;
  }

  &__link {
    margin-right: 2rem;
    padding: 0.5rem;
    font-size: $default-font-size;
    color: $color-white;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    text-align: right;
  }

  &__link.scrolled {
    text-decoration: solid underline white 2px;
  }
}

/*
About
Experiences
Projects
Extracurriculars
Courses
Fun Facts
*/