/* Modal Content/Box */
/*.popup .overlay{
    position: fixed;
    top:20%;
    left:25%;
    width:50vw;
    height:30vw;
    background: $color-black;
    z-index: 100000000;
    display:none;
  }*/


.popup .content {
  position: fixed;
  top: 40%;
  left: 17%;
  margin:auto;
  transform: translate(-50%, -50%) scale(0);
  background: $color-black;
  width: 30vw;
  height: 17vw;
  z-index: 100000000000;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10%;
  border:thick;
  border-color: white;
  border-style: solid;
}

.popup .close-btn {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  width: 30px;
  height: 30px;
  background: #222;
  color: #fff;
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
  border-radius: 50%;
}

.popup.active .overlay {
  display: block;
}

.popup.active .content {
  transition: all 300ms ease-in-out;
  transform: translate(-50%, -50%) scale(1);
}

.header {
  color: $color-secondary;
  font-size:4rem;
  display:inline-block;

}

.body {
  color: white;
  font-size:2rem;
  display:inline-block;

}

.bodycolor {
  color: $color-secondary;
  font-size:2rem;
  display:inline-block;

}

.logo {
  display:inline-block;
  width: 6rem;
  height:6rem;
  margin-left:2rem;
  margin-bottom: -1rem;
}

#blur.active {
  filter: blur(8px);
  pointer-events: none;
  user-select: none;
}