body {
  font-family:"Times New Roman", Georgia, Serif;
  font-weight: 400;
  line-height: 1.7;
}

.heading-primary {
  backface-visibility: hidden;
  margin-bottom: 2rem;

  &--main {
    display: block;
    font-size: 4rem;
    font-weight: 500;

    animation-name: moveInLeft;
    animation-duration: 1s;
    animation-timing-function: ease-out;
  }

  &--sub {
    display: block;
    font-size: 2rem;
    font-weight: 500;
  }
}

.heading-secondary {
  display: block;

  &--main {
    font-size: 2.5rem;
    font-weight: 500;
  }

  &--sub {
    font-size: 1.5rem;
    font-weight: 400;
  }
}

.heading-tertiary {
  font-size: $default-font-size;
  font-weight: 700;
}

.paragraph {
  font-size: $default-font-size;

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}
