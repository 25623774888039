.projects {
  padding: 2rem;
  background-color: $color-white;
  padding-top: $nav-height;

  &__header {
    color: $color-secondary;
    font-size: $font-section;
    text-align: center;
    margin-bottom: -1.5vw;
    font-family: "Langar", cursive;
  }

  &__horizontal {
    width: 5vw;
    margin: auto;
    height: 12px;
    border-radius: 7px;
    background-color: $color-primary;
  }

  &__grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 2vw;
    align-items: start;
    align-self: start;
    font-family: "Times New Roman", Times, serif;
    list-style-position: outside;

    @include respond(tab-land) {
      grid-template-columns: auto auto;
      margin: auto;
    }
    
    @include respond(phone) {
      grid-template-columns: auto;
      margin: auto;
      align-items: center;
      align-self: center;
    }
  }
}
